import React from 'react';
import PropTypes from 'prop-types';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './template.style';
import TextHr from 'common/src/components/textLine';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';
import TaskList from 'common/src/assets/image/saas/templateView.png';
import Fade from 'react-reveal/Fade';
import ConnectImgs from 'common/src/components/connectImages';
import {
  TopBootstrap,
  BottomBootstrap,
} from 'common/src/components/bootstrapView/index.style';
const VisitorSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
}) => {
  const ImageView = () => (
    <SectionObject>
      <Card {...imageWrapper}>
        <Fade right delay={90}>
          <img
            src={TaskList}
            alt="extension view"
            style={{
              borderRadius: '12px',
              boxShadow: '7px 7px 5px 5px #2a313c42',
            }}
          />
        </Fade>
      </Card>
    </SectionObject>
  );
  return (
    <VisitorSectionWrapper id="visitorSection">
      <ConnectImgs />
      <TopBootstrap>
        <ImageView />
      </TopBootstrap>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={
              <>
                <TextHr styleProps={{ backgroundColor: 'white' }} />
                <Text content="PERSONALIZED ENGAGEMENT" {...sectionSubTitle} />
                <Heading
                  content="Personalize, engage and measure."
                  {...title}
                />
              </>
            }
            description={
              <Text
                content="Engage with prospects in a personalized manner. Build and measure strong template and keep track of open and reply rate. Learn what works and what does not."
                {...description}
              />
            }
          />
        </Box>
      </Container>
      <BottomBootstrap>
        <ImageView />
      </BottomBootstrap>
    </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'white',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
    margin: ['auto'],
    marginTop: ['2rem', '3.5rem'],
    justifyContent: 'center',
    display: 'flex',
    width: ['90%', '80%'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '460px', '460px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#e8e8e8',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
