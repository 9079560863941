import styled from 'styled-components';

const VisitorSectionWrapper = styled.section`
  min-height: 630px;
  display: flex;
  align-items: center;
  background: linear-gradient(60deg, #4174ff, #2860f9);

  padding: 6rem 0;
  position: relative;
  @media only screen and (max-width: 1200px) {
    min-height: 500px;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 370px;
    margin: 60px 0;
  }
  @media (max-width: 767px) {
    min-height: auto;
    display: block;
    margin-bottom: 100px;
  }
`;

const SectionObject = styled.div`
  position: absolute;
  width: 55%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    right: auto;
    margin-bottom: 20px;
    img {
      max-width: 100%;
    }
  }
  img {
    max-width: 93%;
    height: auto;
    max-height: 27rem;
  }
  .objectWrapper {
    margin-right: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 8vw;
      right: 5%;
      width: 75%;
    }
  }
`;

export { SectionObject };

export default VisitorSectionWrapper;
